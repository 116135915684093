// 引入用
// 修改的變數
@import "../scss/_variables.scss";

// fontawesome icon
@import "../scss/fontawesome-all";

// bootstrap 核心
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// 自訂文字
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700,900&subset=chinese-traditional');

//SmartMenus
@import "_jquery.smartmenus.bootstrap-4.scss";

//tiny-slider
@import "../../node_modules/tiny-slider/src/tiny-slider.scss";

// 客製化CSS
@import "_header.scss";
@import "_layout.scss";
@import "_custom.scss";
@import "_eng.scss";

