body.en {
  letter-spacing: 0.01rem;

  h1, h2, h3, h4, h5, h6 {
    font-weight: $font-weight-bolder;
  }

  //_hearder ========================
  #main-menu {
    > li, .nav-item {
      > a, > .nav-link {
        font-size: $font-size-base;
        font-weight: 900;
        text-transform: uppercase;
      }
    }
  }

  .mega-menu {
    .mega-menu-items {
      text-transform: capitalize;
    }
  }

  #topLinks {
    font-size: $font-size-sm;
    .nav-link {
      font-weight: 400;
      padding: 0.5rem;
      &:hover, &:active, &.active {
        font-weight: 700;
      }
    }
  }

  //_layout  =====================
  .lead {
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
  .card-usec {
    .card-title {
      font-size: 1.125rem;
    }
    .card-text {
      font-size: $font-size-sm
    }
  }


  //index en ver. =====================
  .pageIndex {
    font-size: $font-size-base;
  }

  //首頁最新消息 en ver.
  .indexNews {
    &-title {
      h2 {
        font-size: 2.5rem;
        @include media-breakpoint-up(lg) {
          font-size: 3rem;
        }
      }
      .lead {
        display: none;
      }
    }
  }

  //最新消息列表 en ver.
  .news-list {
    .list-item {
      .item {
        &-title {
          font-size: 1rem;
          text-transform: uppercase;
          @include media-breakpoint-up(md) {
            font-size: 1.125rem;
          }
        }
        &-text {
          @include media-breakpoint-up(md) {
            height: 72px;
          }
        }
        &-info {
          font-size: $font-size-base;
          letter-spacing: 0.03rem;
        }
      }
    }
  }

  //首頁建案介紹 eng ver.
  .card-project {
    .card-img {
      &-title {
        font-size: $font-size-sm;
        text-transform: uppercase;
      }
    }
    .card-title {
      font-size: $font-size-base;
      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
      }
    }
    .card-text {
      color: $gray-600;
      font-size: $font-size-sm;
      font-weight: 300;
    }
  }

  //首頁工程實績 eng ver.
  .indexPerformance {
    &-title {
      font-size: 1.5rem;
      font-weight: 700;
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
  }

  //實績方塊 en ver.
  .mosaic-list {
    .list-item {
      .slogan {
        font-size: 1.5rem;
        text-transform: uppercase;
        padding: 1rem;
      }
      .item {
        &-title {
          font-size: 1.25rem;
          text-transform: capitalize;
          @include media-breakpoint-up(xl) {
            font-size: 1.5rem;
          }
        }
        &-text {
          @include media-breakpoint-up(xl) {
            font-size: 1.125rem;
          }
        }
      }
    }
  }

  // 內頁基本設定 eng ver. =========================

  //主要文章容器 eng ver.
  .pageArticle {
    font-size: $font-size-base;
    letter-spacing: 0.02rem;
    // line-height: 1.8;
  }

  //內頁Banner eng ver.
  .pageHead {
    &-title {
      h2 {
        font-size: 2rem;
        margin: 1rem 0;
        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 3rem;
        }
      }
      .lead {
        display: none;
      }
    }
  }

  //內頁子選單 eng ver.
  .pageNav-links {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    .list-group-item {
      font-size: $font-size-base;
      font-weight: 900;
      text-transform: uppercase;
      writing-mode: horizontal-tb;
      @include media-breakpoint-down(sm) {
        padding: 1rem 0.325rem;
      }
    }
  }

  //各分頁設定 eng ver. ================================================

  //關於聯鋼 eng ver.======
  .introItem {
    font-size: $font-size-base;
    h4 {
      text-transform: uppercase;
    }
  }

  
  //安衛政策 eng ver.=======
  .safetyItems {
    .media {
      .media-body {
        h3 {
          font-size: 1.25rem;
          text-transform: uppercase;
        }
        @include media-breakpoint-up(md) {
          font-size: 1.125rem;
        }
      }
    }
  }

  .safety-creed {
    h4 {
      font-size: $h4-font-size;
    }
    ul, ol {
      li {
        color: $black;
        margin-bottom: 1rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
  }
  
  
  //品質政策 eng ver.=======
  .qltyItem {
    @include media-breakpoint-up(md) {
      .card-text {
        font-size: 1.125rem;
      }
    }
    .card-title {
      font-size: 1.125rem;
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
  }

  //企業願景 eng ver.=======
  .visionItem {
    &-content {
      p {
        font-size: 1.25rem;
        font-weight: 700;
        @include media-breakpoint-up(xl) {
          font-size: 1.5rem;
        }
      }
    }
  }

  
//工程實績=======

.performanceList {
  .performance-item {
    .card-body {
      @include media-breakpoint-up(md) {
        min-height: 250px;
      }
    }
    .item-title {
      text-transform: uppercase;
      font-size: 1.125rem;
      max-height: none;
      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
        max-height: 72px;
      }
    }
  }
}

.item-info, .performancePage-info {
  font-size: 1rem;
  @include media-breakpoint-up(xxl) {
    font-size: 1.125rem;
  }
}

  //工程實績內頁 ======
  .page-bg-line-top {
    background-size: 100% 180px;
    @include media-breakpoint-up(sm) {
      background-size: 100% 160px;
    }
    @include media-breakpoint-up(md) {
      background-size: 100% 180px;
    }
    @include media-breakpoint-up(lg) {
      background-size: 100% 240px;
    }
    @include media-breakpoint-up(xl) {
      background-size: 100% 275px;
    }
    @include media-breakpoint-up(xxl) {
      background-size: 100% 300px;
    }
  }

  .performancePage {
    &-title {
      font-size: 1.125rem;
      text-transform: uppercase;
      min-height: 96px;
      margin-bottom: 2rem;
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        min-height: 110px;
        padding-top: 1rem;
        background-position: left 24px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
        min-height: 128px;
        margin-bottom: 2.5rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.75rem;
        min-height: 168px;
        margin-bottom: 1.5rem;
      }
    }
  }

  //聯絡我們 eng ver. =======

  //聯絡卡 eng ver.
  .cardContact {
    .card-body {
      padding-top: 0.5rem;
    }
    .card-title {
      font-size: 1.25rem;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        min-height: 48px;
      }
    }
    .card-info {
      letter-spacing: 0.063rem;
    }
  }

  //聯絡表單 eng ver.
  .contactForm {
    .form {
      @include media-breakpoint-up(xl) {
        max-width: 920px;
      }
    }
    .lead {
      font-size: 1.5rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
      }
    }
    .form-group {
      @include media-breakpoint-up(xxl) {
        padding: 0.875rem 0;
      }
    }
    .col-form-label-lg {
      font-size: $font-size-base;
      font-weight: 700;
    }
    .form-control {
      font-size: $font-size-base;
      font-weight: 300;
    }
  }

  //網站地圖 eng ver.
  .sitemapList {
    > li { 
      .title {
        font-size: 1.25rem;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }
      }
    }
  }

  
}
