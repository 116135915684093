//網站選單設定
.navbar-usec {
    background-color: $white;
    box-shadow: $box-shadow-sm;
    z-index: $zindex-fixed;
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
    //收合時固定選單位置
    @include media-breakpoint-down(lg) {
      min-height: $nav-height-m;
      box-shadow: $box-shadow;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      .navbar-collapse {
        max-height: 80vh;
        overflow-y: auto;
      }
    }
    > [class*='container'] {
      align-items: center;
      @include media-breakpoint-up(xxl) {
        padding: 0 4.5rem;
      }
    }
  //LOGO
  .navbar-brand {
    @include text-hide;
    padding: 0;
    margin: 0;
    img {
      max-width: 180px;
      height: auto;
      @include media-breakpoint-up(xl) {
        max-width: 220px;
      }
      @include media-breakpoint-up(xxl) {
        max-width: 300px;
      }
    }
  }
  //語系下拉選項設定
  .dropdown.lang { 
    margin-left: 0.5rem;
    @include media-breakpoint-down(lg) {
      display: none;
    }
    > .btn {
      width: 100%;
      white-space: nowrap;
      display: block;
      margin: 0.5rem 0;
    }
    .dropdown-menu {
      border-radius: 0.125rem;
      box-shadow: $box-shadow;
      margin: 0.5rem 0;
      right: 0;
      bottom: 100%;
      top: auto;
      @include media-breakpoint-up(xl) {
        border: 0;
        top: 100%;
        right: 0;
        bottom: auto;
        left: auto;
      }
    }
  }
}

//主要選單設定
#main-menu {
  @include media-breakpoint-up(xl) {
    flex-wrap: nowrap;
  }
  > li, .nav-item {
    > a, > .nav-link {
      color: $text-color;
      font-size: 1rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
      &:hover, &:active, &.active {
        background-color: rgba($main-color01, 0.06);
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
        padding: 1.5rem 1rem;
        border-top: solid 1rem transparent;
        border-bottom: solid 1rem transparent;
        &:hover, &:active, &.active, &.highlighted {
          color: $main-color01;
          background-color: transparent;
          border-bottom-color: $main-color01;
        }
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.25rem;
        padding: 3rem 1.5rem;
      }
    }
  }
}

//mega menus展開設置
#main-menu {
  padding: 1rem 0;
  position: static !important;
  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  > .has-mega-menu {
    position: static !important;
  }

  .mega-menu {
    width: auto !important;
    max-width: none !important;
    margin-left: 0 !important;
    padding: 1rem 0;
    right: 0 !important;
    z-index: $zindex-fixed;
    @include media-breakpoint-down(md) {
      padding: $dropdown-item-padding-y 0;
    }
    @include media-breakpoint-up(xl) {
      margin-top: -1px;
      background-color: $main-color01;
      border: 0;
      border-bottom: solid darken($main-color01, 10) 0.625rem;
      box-shadow: 0 0.5rem 1.5rem rgba($main-color02, 0.5);
    }

    /* clear any floats inside the mega menus */
    > li {
      overflow: auto;
      @include media-breakpoint-down(xl) {
        &.container {
          max-width: none;
        }
      }
    }
  }

  > .has-mega-menu {
    .scroll-up, .scroll-down {
      margin-left: 0 !important;
    }
  }
}

// mega menus type setting
.mega-menu {
  > .container {
    justify-content: center;
  }
  //mega-menu-items主要設定
  .mega-menu-items {
    margin: 1rem;
    display: block;
    @include media-breakpoint-up(xl) {
      color: $white;
      font-size: 1.125rem;
      display: inline-block;
      border: solid 1px transparent;
      padding: 0.5rem 1rem;
      &:hover {
        border: solid 1px $white;
      }
    }
    @include media-breakpoint-up(xxl) {
      padding: 0.5rem 1.5rem;
    }
  }

  //行動版設定
  @include media-breakpoint-down(lg) {
    > .container {
      flex-direction: row;
      padding:  0.25rem 0;
    }
    .mega-menu-items {
      width: 100%;
      margin: 0;
      padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      &:hover, &:active {
        color: $white;
        background-color: $main-color01;
      }
    }
  }
}

//上方右側副選單
#topLinks {
  font-size: $font-size-base;
  .nav-link {
    color: $text-color;
    font-weight: 400;
    padding: 0.5rem 1rem;
    &:hover, &:active, &.active {
      color: $main-color01;
      font-weight: 700;
    }
  }
  @include media-breakpoint-up(xl) {
    font-size: $font-size-sm;
    flex-wrap: nowrap;
    .nav-item.lang {
      display: none;
    }
    .nav-item:not(:last-child){
      white-space: nowrap;
      &:after {
        content: "|";
        padding: 0 0.188rem;
      }
    }
    .nav-link {
      padding: 0.5rem;
      display: inline-block;
    }
  }
  @include media-breakpoint-up(xxl) {
    font-size: $font-size-base;
  }
}

//safari css hack fix
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .navbar-usec {
    display: block;
  }
}}