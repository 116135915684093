// 客製用
// @include media-breakpoint-down(xs) {}
// @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(md) {}
// @include media-breakpoint-down(lg) {} XXXX以下
// @include media-breakpoint-between(md, xl) {}  特殊區間
// @include media-breakpoint-up(xs) { ... }   XXXX以上

// 主要組件 ==============================================

//INDEX =========================
.indexBanner {
  width: 100%;
  .banner-item {
    &-img {
      @include text-hide;
      padding-top: calc(100vh - 56px);
      @include media-breakpoint-up(sm) {
        padding-top: 50.78%;
      }
      
    }
  }
}

.pageIndex {
  font-size: 1rem;
  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 1.25rem;
  }
}

//首頁最新消息
.indexNews {
  background: no-repeat center center;
  background-image: url(../images/img_indexnews.jpg);
  background-size: cover;
  &-title {
    text-align: center;
    position: relative;
    h2, .lead {
      position: relative;
      z-index: 1;
      span {
        padding: 0 2rem;
        background-color: $white;
        display: inline-block;
      }
    }
    h2 {
      color: $main-color01;
      font-size: 2rem;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 4rem;
      }
    }
    .lead {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.875rem;
      }
    }
    hr {
      border-color: rgba($black, 0.5);
      margin: 0;
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      z-index: 0;
    }
  }
  &-photo {
    background: url(../images/img_indexnews_side.jpg) no-repeat;
    background-position: right center;
    background-size: 80%;
    @include flex-center;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 520px;
      background-position: 85% center;
      background-size: auto;
    }
    img {
      @include media-breakpoint-up(md) {
        max-width: 340px;
      }
      @include media-breakpoint-up(xxl) {
        max-width: 480px;
      }
    }
  }
}

//最新消息列表
.news-list {
  li {
    padding: 1rem 0;
    display: block;
    @include media-breakpoint-up(sm) {
      padding: 1.875rem 0;
    }
  }
  .list-item {
    color: $text-color;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    .item {
      &-img {
        @include text-hide;
        width: 100%;
        height: 240px;
        margin: 0 auto 1rem;
        background-color: $gray-200;
        @include media-breakpoint-up(md) {
          flex: 0 0 125px;
          max-width: 125px;
          height: 125px;
          margin: 0;
          & ~ .item-body {
            margin-left: 1.25rem;
          }
        }
      }
      &-body {
        min-width: 0;//防止text-ellipsis撐開容器
        flex: 1;
        display: block;
      }
      &-title {
        font-size: 1.125rem;
        @include media-breakpoint-up(md) {
          @include text-ellipsis;
          font-size: 1.25rem;
        }
        @include media-breakpoint-up(xxl) {
          font-size: 1.375rem;
        }
      }
      &-text {
        margin-bottom: 0;
        display: none;
        @include media-breakpoint-up(md) {
          display: block;
          overflow: hidden;
        }
        @include media-breakpoint-up(xl) {
          height: 54px;
        }
        @include media-breakpoint-up(xxl) {
          height: 60px;
        }
      }
      &-info {
        color: $gray-500;
        font-size: 0.938rem;
        font-weight: 400;
        line-height: 1;
        @include media-breakpoint-up(xxl){
          font-size: 1.125rem;
        }
      }
    }
    &:hover, &:focus, &:active {
      .item-title {
        color: $main-color01;
      }
    }
  }
}

//首頁建案介紹
.card-project {
  .card-img {
    font-weight: 700;
    padding-top: 125%;
    position: relative;
    @include background-img;
    @include media-breakpoint-up(xl) {
      padding-top: 195%;
    }
    &-cover {
      color: $white;
      background-color: rgba($black, 0.4);
      @include flex-center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &-title {
      font-size: $font-size-base;
      position: absolute;
      padding: 1rem 1.5rem;
      top: auto;
      right: 0;
      bottom: 0.5rem;
      left: 0;
    }
    .project-mark {
      font-size: 0.875rem;
      text-align: center;
      padding: 1rem;
      img {
        margin: 1rem auto;
        display: block;
      }
    }
    &:hover {
      box-shadow: $box-shadow-blue;
      z-index: 10;
      .card-img-cover {
        background-color: rgba($black, 0);
      }
    }
  }
  .card-body {
    @include media-breakpoint-up(lg) {
      padding: 1.5rem;
    }
  }
  .card-title {
    font-size: 1.125rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.5rem;
    }
  }
  .card-text {
    color: $gray-500;
    font-size: $font-size-base;
    font-weight: 400;
  }
}

//首頁工程實績
.indexPerformance {
  background: #f4f4f4 url(../images/img_indexperformance.jpg) no-repeat center top;
  background-size: auto;
  &-title {
    // color: $white;
    color: $orange;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    padding: 1.5rem 0;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 2.625rem;
    }
  }
}

//實績方塊
.mosaic-list {
  padding: 0;
  background-color: $white;
  box-shadow: $box-shadow-blue;
  .list-item {
    text-align: center;
    @include flex-center;
    .slogan {
      font-size: 1.25rem;
      padding: 2rem 1rem;
      font-weight: 900;
      @include media-breakpoint-up(lg) {
        font-size: 1.625rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2.125rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 2.5rem;
      }
    }
    .item {
      &-img {
        color: $white;
        width: 100%;
        opacity: 0.5;
        position: relative;
        &:hover {
          opacity: 1;
          .item-body {
            opacity: 1;
          }
        }
      }
      &-body {
        padding: 1rem;
        display: flex;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba($black,0.65) 0%, rgba($black,0) 40%, rgba($black,0) 100%);
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
      }
      &-title {
        font-size: 1.25rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.625rem;
        }
        @include media-breakpoint-up(xxl) {
          font-size: 2rem;
        }
      }
      &-text {
        font-size: 1rem;
        @include media-breakpoint-up(xxl) {
          font-size: 1.375rem;
        }
      }
    }
  }
}

//客戶LOGO列表
.indexClient {
  background-color: #d4dce4;
}

.logo-list {
  &-item {
    @include text-hide;
    width: 100%;
    text-align: center;
    height: 64px;
    background: transparent no-repeat center center;
    background-size: contain;
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      height: 120px;
    }
  }
  .slideList-item {
    padding: 1.25rem;
  }
  .slideList-controls {
    .prev, .next {
      color: $white;
    }
  }
}

// 內頁基本設定 =========================

//主要文章容器
.pageArticle {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.8;
  @include media-breakpoint-up(xxl) {
    font-size: 1.25rem;
  }
  ul.usecAbout {
    list-style: none;
    padding-left:1rem;
    > li {
      &:before {
        content: "\2022";
        color: $main-color01;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        width: 1rem;
        display: inline-block; 
        margin-left: -1rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
    }
  }
}

//內頁Banner及麵包屑
.pageHead {
  color: $white;
  width: 100%;
  min-height: 15rem;
  display: block;
  position: relative;
  box-shadow: $box-shadow-blue;
  &-body {
    width: 100%;
    display: flex;
    background-color: rgba($black, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    > .container {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }
  &-title {
    h2 {
      font-size: 2rem;
      margin: 1rem 0;
      @include media-breakpoint-up(md) {
        font-size: 2.625rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 4rem;
      }
    }
  }
  
  @include media-breakpoint-down(md) {
    &-title {
      width: 100%;
    }
    .lead {
      font-size: 1.125rem;
    }
  }
}

.breadcrumb {
  font-size: $font-size-base;
  background-color: transparent;
  justify-content: center;
  &-item, &-item a {
    color: $gray-400;
  }
  &-item.active, &-item.active a {
    color: $white;
  }
}

.pageHead + .pageNav {
  @include media-breakpoint-up(md) {
    margin-top: -1.875rem;
    margin-bottom: 1.125rem;
  }
}

.page-title-liner {
  text-align: center;
  position: relative;
  .title {
    color: $main-color01;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    span {
      padding: 0 2rem;
      background-color: $white;
      display: inline-block;
    }
  }
  hr {
    border-color: rgba($black, 0.3);
    margin: 0;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 0;
  }
}

//內頁子選單
.pageNav-links {
  box-shadow: $box-shadow-blue;
  overflow: auto;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  .list-group-item {
    font-size: 1.125rem;
    font-weight: 500;
    writing-mode: vertical-rl;
    border: 0;
    padding: 1.125rem 0.325rem;
    @include flex-center;
    flex-wrap: wrap;
    &.active {
      background-color: $main-color06;
    }
    &:not(.active):hover {
      color: $main-color01;
    }
    @include media-breakpoint-up(md) {
      writing-mode: horizontal-tb;
    }
  }
}

//pagination
.pagination-usec {
  justify-content: center;
  flex-wrap: wrap;
  .page-item {
    margin: 0.315rem 0.75rem;
    .page-link {
      font-weight: 300;
      padding: 0.75rem 1.25rem;
      border-radius: $rounded-pill;
      box-shadow: $box-shadow-sm;
    }
    &:first-child {
      .page-link {
        @include border-left-radius($rounded-pill);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($rounded-pill);
      }
    }
  }
}

//各種背景
.bg-light-main {
  background: rgba($main-color01 , 0.1);
}

.page-bg-line {
  background: $white;
  background: linear-gradient(0deg, rgba($white,1) 35%, rgba($main-color01,0.3) 35%, rgba($main-color01,0.3) 65%, rgba($white,1) 65%);
}

.page-bg-block-r {
  background: $white;
  background: linear-gradient(90deg, rgba($white,1) 30%, rgba($main-color01,0.1) 30%, rgba($main-color01,0.1) 100%, rgba($white,1) 100%);
}


.page-bg-block-l {
  background: $white;
  background: linear-gradient(270deg, rgba($white,1) 30%, rgba($main-color01,0.1) 30%, rgba($main-color01,0.1) 100%, rgba($white,1) 100%);
}

.btn-page {
  color: $text-color;
  min-width: 10rem;
  background-color: $white;
  border-color: $white;
  box-shadow: $box-shadow-sm;
  border-radius: $rounded-pill;
  &:hover, &:active {
    color: $white;
    background-color: $gray-600;
    border-color: $gray-600;
  }
}


//各分頁設定 ================================================

//最新消息內頁
.newsPage {
  img {
    @include img-fluid();
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &-title {
    font-size: 1.25rem;
    font-weight: 900;
    padding-bottom: 1.5rem;
    border-bottom: solid 3px rgba($main-color01 , 0.2);
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
  &-date {
    color: $gray-500;
    font-size: $font-size-base;
    font-weight: 700;
  }
  &-content {
    padding: 1.5rem 0;
    a:hover {
      text-decoration: underline;
    }
  }
}

//關於聯鋼 ======

.introItem {
  color: $white;
  font-size: 1.125rem;
  text-align: center;
  &-icon {
    width: 90px;
    height: auto;
  }
}


//經營理念 ======
.card.business {
  .card-title {
    font-size: 1.25rem;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
  .card-body {
    @include media-breakpoint-up(md) {
      min-height: 16.875rem;
    }
    @include media-breakpoint-up(xxl) {
      min-height: 21rem;
    }
  }
}

//安衛政策=======

.safetyItems {
  .media {
    text-align: center;
    margin-bottom: 2rem;
    align-items: center;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      margin-bottom: 5rem;
      text-align: left;
      flex-direction: row;
      align-items: flex-start;
    }
    .media-body {
      padding: 1rem 0;
      h3 {
        font-size: $h4-font-size;
      }
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
        h3 {
          font-size: $h3-font-size;
        }
      }
    }
    img {
      width: 120px;
      @include media-breakpoint-up(md) {
        width: 152px;
        margin-right: 2rem;
      }
    }
  }
}

.safety-creed {
  text-align: center;
  background-color: transparent;
  h4 {
    @include media-breakpoint-up(xxl) {
      font-size: $h3-font-size;
    }
  }
  ul, ol {
    line-height: 1.5;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.5rem;
    }
    li {
      text-align: left;
      margin-bottom: 0.315rem;
    }
  }
  &-img {
    @include media-breakpoint-up(md) {
      max-width: 320px;
    }
  }
}

.safety-bot {
  h5 {
    font-size: $h4-font-size;
    @include media-breakpoint-up(xxl) {
      font-size: $h3-font-size;
    }
  }
  p {
    
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.5rem;
    }
  }
}

.safety-list {
  .slideList-item {
    text-align: center;
    cursor: grab;
  }
}


//品質政策=======

.qltyItem {
  @include media-breakpoint-up(md) {
    max-width: 720px;
    // margin-left: auto; //靜態修改
    // margin-right: auto; //靜態修改
  }
  @include media-breakpoint-up(xl) {
    .card-body {
      min-height: 400px;
    }
  }
  @include media-breakpoint-up(xxl) {
    //max-width: 1024px; //靜態修改
    .card-body {
      min-height: 510px;
    }
  }
  .card-title {
    color: $white;
    font-size: 1.25rem;
    text-align: center;
    min-width: 200px;
    background: $main-color01;
    background: linear-gradient(90deg, rgba(44,44,45,0.9) 0%, rgba($main-color01,0.9) 35%);
    padding: 1rem 2rem;
    display: block;
    position: relative;
    margin-left: -4rem;
    @include media-breakpoint-up(sm) {
      width: 50%;
      width: fit-content;
      min-width: 320px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
  .card-text {
    font-weight: 400;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.5rem;
    }
  }
}

.certificates {
  background: no-repeat center center;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    margin-top: 6rem;
  }
  > .container > .row {
    justify-content: center;
  }
  &-img {
    margin: 2rem 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: -3.5rem;
      margin-bottom: 4.5rem;
    }
  }
}

//企業願景=======

.visionItem {
  margin-bottom: 4rem;
  @include media-breakpoint-up(lg) {
    margin: 2rem 0 10rem;
  }
  &-img {
    @include text-hide;
    box-shadow: $box-shadow-blue;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      margin: -2rem 0;
    }
  }
  &-content {
    width: 100%;
    min-height: 320px;
    @include flex-center;
    flex-direction: column;
    border: solid rgba($main-color01,0.4) 1rem;
    @include media-breakpoint-up(xxl) {
      min-height: 450px;
      border-width: 1.25rem;
    }
    p {
      font-size: 1.25rem;
      font-weight: 700;
      padding: 1rem;
      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
        max-width: 80%;
      } 
      @include media-breakpoint-up(xxl) {
        font-size: 1.875rem;
      }
    }
  }
  hr.divide {
    width: 210px;
    max-width: 90%;
    height: 10px;
    border: 0;
    display: inline-block;
    background: $white;
    background: linear-gradient(270deg, rgba($white,1) 50%, rgba($main-color01,1) 50%, rgba($main-color01,1) 100%, rgba($white,1) 100%);
  }
}

.page-bg-block-l {
  .visionItem  {
    justify-content: flex-end;
    hr.divide {
      background: linear-gradient(90deg, rgba($white,1) 50%, rgba($main-color01,1) 50%, rgba($main-color01,1) 100%, rgba($white,1) 100%);
    }
  }
}

//工程實績=======

.performanceList {
  .performance-item {
    color: $text-color;
    margin: 1.875rem 0;
    box-shadow: 0 0 2.25rem rgba($main-color02,0.2);
    //照片&遮罩預設
    .card-img-top {
      background-image: url(../images/img_performancelist_default.jpg);
      // &::before {
      //   content: "";
      //   display: block;
      //   background: rgba($main-color01 , 0.8) url(../images/cover_performancelist.png) no-repeat center;
      //   background-size: cover;
      //   opacity: 0;
      //   transition: all .2s ease-in;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      // }
    }
    .card-body {
      @include media-breakpoint-up(md) {
        min-height: 260px;
      }
    }
    .item-title {
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.5;
      max-height: none;
      overflow: hidden;
      @include media-breakpoint-up(xl) {
        font-size: 1.315rem;
        max-height: 64px;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.625rem;
        max-height: 80px;
      }
    }
    &:hover {
      opacity: 0.9;
      // .card-img-top::before {
      //   opacity: 1;
      // }
      .item-title {
        color: $main-color01;
      }
    }
  }
}

.item-info, .performancePage-info {
  line-height: 1.5;
  margin-bottom: 0;
  @include media-breakpoint-up(xxl) {
    font-size: 1.5rem;
  }
  dt {
    color: $gray-500;
    display: flex;
    &::after {
      content: '|';
      color: $gray-400;
      margin-left: auto;
    }
  }
  dt, dd {
    font-weight: 400;
  }
}

//工程實績內頁 ======
.page-bg-line-top {
  background: $white url(../images/bg_performancePage.png)repeat-x top;
  background-size: 100% 145px;
  @include media-breakpoint-up(sm) {
    background-size: 100% 135px;
  }
  @include media-breakpoint-up(md) {
    background-size: 100% 180px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 100% 220px;
  }
  @include media-breakpoint-up(xl) {
    background-size: 100% 250px;
  }
  @include media-breakpoint-up(xxl) {
    background-size: 100% 300px;
  }
}

.performancePage {
  @include media-breakpoint-up(lg) {
     min-height: 30rem;
  }
  &-title {
    font-size: 1.25rem;
    line-height: 1.5;
    min-height: 88px;
    padding-left: 0.875rem;
    margin-bottom: 0.5rem;
    background: url(../images/performance_title_line.svg) no-repeat;
    background-position: left 4px;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      min-height: 98px;
      padding-top: 1rem;
      margin-bottom: 2rem;
      background-position: left 24px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 88px;
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(xxl) {
      font-size: 1.875rem;
      min-height: 138px;
      margin-bottom: 2.875rem;
    }
  }
  &-img {
    margin: -1.5rem auto 0;
    @include media-breakpoint-up(xl) {
      max-width: 688px;
    }
    .carousel-inner {
      padding: 1.5rem;
    }
    .img-item {
      background-color: $white;
      img {
        width: 100%;
        display: block;
        box-shadow: $box-shadow-blue-sm;
      }
    }
    .carousel-indicators {
      position: static;
      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        background-color: $main-color01;
      }
    }
  }
 
}

//聯絡我們 =======

//聯絡卡
.cardContact {
  box-shadow: $box-shadow-blue-sm;
  .card-img {
    height: 150px;
    @include flex-center;
    img {
      max-width: 100px;
      max-height: 84px;
    }
  }
  .card-body {
    @include media-breakpoint-up(xl) {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  .card-title {
    font-size: 1.5rem;
    text-align: center;
    @include media-breakpoint-up(xxl) {
      font-size: 1.75rem;
    }
  }
  .card-info {
    color: $main-color01;
    font-size: 1rem;
    word-break: break-all;
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include media-breakpoint-up(xxl) {
      font-size: 1.125rem;
    }
    .info-icon {
      color: $white;
      font-size: 0.875rem;
      text-align: center;
      line-height: 24px;
      width: 24px;
      height: 24px;
      background-color: $gray-500;
      border-radius: 50%;
      display: inline-block;
      @include media-breakpoint-up(xl) {
        line-height: 32px;
        width: 32px;
        height: 32px;
      }
    }
  }
}

.contactMap {
  max-height: 480px;
}

//聯絡表單
.contactForm {
  color: $white;
  .lead {
    font-size: 1.5rem;
    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }
  .form {
    @include media-breakpoint-up(xl) {
      max-width: 860px;
    }
  }
  .form-group {
    border-bottom: solid 1px rgba($white, 0.5);
    padding: 0.875rem 0;
    @include media-breakpoint-up(xxl) {
      padding: 1rem 0;
    }
  }
  .form-control {
    color: $white;
    background-color: transparent;
    border-color: transparent;
    &:hover, &:active, &:focus {
      background-color: rgba($black, 0.1);
    }
    &::placeholder {
      color: rgba($white, 0.3);
    }
    option {
      color: $text-color;
    }
  }
  .btn {
    width: 160px;
    &.btn-lg {
      width: 240px;
      padding: 1rem;
    }
  }
}

//網站地圖
.sitemapList {
  margin: 1rem auto;
  @include media-breakpoint-up(xl) {
    max-width: 75%;
  }
  a {
    color: $text-color;
    width: 100%;
    display: block;
    &:hover {
      color: $main-color01;
    }
  }
  > li { 
    margin-bottom: 2rem;
    .title {
      font-size: 1.25rem;
      font-weight: 500;
      padding: 0;
      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
      }
      > a, > span {
        padding: 1.25rem 1rem;
        display: block;
        background-color: $gray-100;
        border-left: solid 0 transparent;
        transition: all .3s ease-in;
        &:hover {
          color: $main-color01;
          background-color: $white;
          border-left: solid 0.5rem $main-color01;
          box-shadow: $box-shadow-blue-sm;
        }
      }
    }
  }
  .sublist {
    li {
      margin: 1rem 0;
      a {
        padding: 0.625rem 1rem;
        border: solid 1px $gray-300;
        &:hover {
          padding-left: 1.5rem;
          background-color: rgba($main-color01 , 0.05);
          border-color: transparent;
        }
      }
    }
  }
}