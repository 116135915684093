html {
  height: 100% !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  color: $text-color;
  font-family: $font-family-base;
  font-weight: $font-weight-light;
  letter-spacing: 0.063rem;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

a,
a * {
  transition: all .2s ease-in;

  &:hover {
    text-decoration: none;
  }
}

.lead {
  @include media-breakpoint-up(xxl) {
    font-size: 1.5rem;
  }
}

.flex-center {
  @include flex-center;
}

.box-shadow-blue {
  box-shadow: $box-shadow-blue;
}

.goTop {
  color: $white;
  text-shadow: $box-shadow;
  position: fixed;
  bottom: 4rem;
  right: 0.625rem;
  z-index: $zindex-sticky;
  display: none;

  i {
    font-size: 2.5rem;
  }

  &:hover {
    color: $main-color01;
  }
}

input.form-control::placeholder {
  color: $gray-500;
}


// .Accessible {
//   color: transparent;
//   outline: none;
//   cursor: default;
//   position: absolute;
//   &:hover{
//     color: transparent;
//   }
// }

.background-img {
  width: 100%;
  padding: 0;
  display: block;
  background: $white no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &.img-banner {
    padding-top: 20.833%;
  }

  &.img-21by9 {
    padding-top: 42.857%;
  }

  &.img-16by9 {
    padding-top: 56.25%;
  }

  &.img-4by3 {
    padding-top: 75%;
  }

  &.img-1by1 {
    padding-top: 100%;
  }

  &.img-1by1p {
    padding-top: 110%;
  }
}

//主要內容外容器
main {
  flex-shrink: 0;
  position: relative;

  //收合時避開選單高度
  @include media-breakpoint-down(lg) {
    padding-top: $nav-height-m;
  }
}

//footer
footer {
  margin-top: auto;
  background: $gray-100 url(../images/img_footer.jpg) center no-repeat;
  background-size: cover;
  box-shadow: $box-shadow-blue;

  .footer-privacy-btn {
    margin-right: 1rem;
  }

  .footer-copyright {
    color: $gray-200;
    font-size: 0.875rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: $main-color03;
    background: linear-gradient(90deg, $main-color03 0%, $main-color04 100%);

    @include media-breakpoint-up(xl) {
      font-size: $font-family-base;
    }

    a {
      color: $white;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }

  .footer-info {
    padding: 3rem 0;

    @include media-breakpoint-up(xl) {
      padding: 6rem 0 5rem;
    }

    >.container>.row>[class*='col-lg'] {
      margin-bottom: 1rem;
      display: flex;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;

        &:first-of-type {
          justify-content: flex-start;
        }

        &:last-of-type {
          justify-content: flex-end;
        }
      }
    }

    &-title {
      font-size: 1.625rem;
      font-weight: 900;

      @include media-breakpoint-up(xxl) {
        font-size: 1.875rem;
      }
    }

    &-block {
      @include media-breakpoint-down(md) {
        text-align: center;
        width: 100%;
      }

      h6:not(.footer-info-title) {
        letter-spacing: 0.325rem;
      }

      .small {
        font-size: 75%;
      }

      img {
        max-width: 180px;

        @include media-breakpoint-up(xl) {
          max-width: 220px;
        }

        @include media-breakpoint-up(xxl) {
          max-width: 250px;
        }
      }

      dl {
        font-size: 0.875rem;

        @include media-breakpoint-up(xxl) {
          font-size: 1rem;
        }
      }

      dt {
        font-weight: 300;
      }
    }

    &-sns {
      a {
        color: $gray-600;
        font-size: 1.25rem;
        line-height: 1.2;
        padding: 0.313rem;
        margin: 0.25rem;
        display: inline-block;

        &:hover {
          color: $main-color03;
        }
      }
    }
  }
}


.pagination {
  .page-link {
    font-weight: 500;
  }
}



.modal-title {
  font-size: 1rem;
}

.card-columns {
  @include media-breakpoint-up(sm) {
    column-count: 2;
  }

  @include media-breakpoint-up(md) {
    column-count: 3;
  }

  @include media-breakpoint-up(xl) {
    column-count: 4;
  }

  .card {
    margin-bottom: 1rem;
  }
}

.card {
  margin-bottom: 1rem;
  border: 0;

  .background-img {
    @include text-hide;
  }

  &.card-usec,
  &.card-usec-sm {
    margin-bottom: 1.5rem;
  }

  &.card-usec {
    box-shadow: $box-shadow-blue;
  }

  &.card-usec-sm {
    box-shadow: $box-shadow-blue-sm;
  }

  &+.card {
    margin-bottom: 1.5rem;
  }
}

.min-350 {
  @include media-breakpoint-up(md) {
    min-height: 21.875rem;
  }
}

//tns自訂容器
.slideList {
  position: relative;

  &-item {
    padding: 1.25rem;

    @include media-breakpoint-up(xl) {
      padding: 1.875rem;
    }
  }

  &-controls {

    .prev,
    .next {
      color: rgba($main-color01, 0.45);
      font-size: 2.5rem;
      margin-top: -1.25rem;
      padding: 0;
      position: absolute;
      top: 45%;
      z-index: 20;

      &:disabled,
      &.disabled {
        color: $gray-400;
      }
    }

    .prev {
      left: 0;
    }

    .next {
      right: 0;
    }

    @include media-breakpoint-up(xl) {

      .prev,
      .next {
        font-size: 3rem;
        margin-top: -1.5rem;
      }

      .prev {
        left: -2rem;
      }

      .next {
        right: -2rem;
      }
    }
  }
}